import _pickNonNull2 from "../../pick-non-null.js";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.todo_changeUserPassword = todo_changeUserPassword;
exports.todo_getSelfCliTokens = todo_getSelfCliTokens;
exports.todo_getConfirmationEmail = todo_getConfirmationEmail;
exports.todo_getEmailAddresses = todo_getEmailAddresses;
exports.todo_removeEmailAddress = todo_removeEmailAddress;
exports.todo_addEmailAddress = todo_addEmailAddress;
exports.getId = getId;
exports.todo_getSshKeys = todo_getSshKeys;
exports.todo_removeSshKey = todo_removeSshKey;
exports.todo_addSshKey = todo_addSshKey;
exports.todo_deleteMFA = todo_deleteMFA;
exports.todo_createMFA = todo_createMFA;
exports.todo_favMFA = todo_favMFA;
exports.todo_getBackupCodes = todo_getBackupCodes;
exports.todo_validateMFA = todo_validateMFA;
exports.todo_revokeAllTokens = todo_revokeAllTokens;
exports.todo_getSelfTokens = todo_getSelfTokens;
exports.todo_revokeToken = todo_revokeToken;
exports.todo_validateEmail = todo_validateEmail;
exports.getSummary = getSummary;
exports.create = create;
var _pickNonNull = _pickNonNull2;
/**
 * PUT /self/change_password
 * @param {Object} params
 * @param {Object} body
 */

function todo_changeUserPassword(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "put",
    url: `/v2/self/change_password`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /self/cli_tokens
 * @param {Object} params
 * @param {String} params.cli_token
 */


function todo_getSelfCliTokens(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/cli_tokens`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["cli_token"]) // no body

  });
}
/**
 * GET /self/confirmation_email
 * @param {Object} params
 */


function todo_getConfirmationEmail() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/confirmation_email`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /self/emails
 * @param {Object} params
 */


function todo_getEmailAddresses() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/emails`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /self/emails/{email}
 * @param {Object} params
 * @param {String} params.email
 */


function todo_removeEmailAddress(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "delete",
    url: `/v2/self/emails/${params.email}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /self/emails/{email}
 * @param {Object} params
 * @param {String} params.email
 * @param {Object} body
 */


function todo_addEmailAddress(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "put",
    url: `/v2/self/emails/${params.email}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /self/id
 * @param {Object} params
 */


function getId() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/id`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /self/keys
 * @param {Object} params
 */


function todo_getSshKeys() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/keys`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /self/keys/{key}
 * @param {Object} params
 * @param {String} params.key
 */


function todo_removeSshKey(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "delete",
    url: `/v2/self/keys/${params.key}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /self/keys/{key}
 * @param {Object} params
 * @param {String} params.key
 * @param {Object} body
 */


function todo_addSshKey(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "put",
    url: `/v2/self/keys/${params.key}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * DELETE /self/mfa/{kind}
 * @param {Object} params
 * @param {String} params.kind
 */


function todo_deleteMFA(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "delete",
    url: `/v2/self/mfa/${params.kind}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * POST /self/mfa/{kind}
 * @param {Object} params
 * @param {String} params.kind
 */


function todo_createMFA(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "post",
    url: `/v2/self/mfa/${params.kind}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * PUT /self/mfa/{kind}
 * @param {Object} params
 * @param {String} params.kind
 * @param {Object} body
 */


function todo_favMFA(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "put",
    url: `/v2/self/mfa/${params.kind}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * GET /self/mfa/{kind}/backupcodes
 * @param {Object} params
 * @param {String} params.kind
 */


function todo_getBackupCodes(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/mfa/${params.kind}/backupcodes`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * POST /self/mfa/{kind}/confirmation
 * @param {Object} params
 * @param {String} params.kind
 * @param {Object} body
 */


function todo_validateMFA(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "post",
    url: `/v2/self/mfa/${params.kind}/confirmation`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // no query params
    body
  });
}
/**
 * DELETE /self/tokens
 * @param {Object} params
 */


function todo_revokeAllTokens() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "delete",
    url: `/v2/self/tokens`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /self/tokens
 * @param {Object} params
 */


function todo_getSelfTokens() {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/tokens`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * DELETE /self/tokens/{token}
 * @param {Object} params
 * @param {String} params.token
 */


function todo_revokeToken(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "delete",
    url: `/v2/self/tokens/${params.token}`,
    headers: {
      Accept: "application/json"
    } // no query params
    // no body

  });
}
/**
 * GET /self/validate_email
 * @param {Object} params
 * @param {String} params.validationKey
 */


function todo_validateEmail(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/self/validate_email`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["validationKey"]) // no body

  });
}
/**
 * GET /summary
 * @param {Object} params
 * @param {String} params.full
 */


function getSummary(params) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "get",
    url: `/v2/summary`,
    headers: {
      Accept: "application/json"
    },
    queryParams: (0, _pickNonNull.pickNonNull)(params, ["full"]) // no body

  });
}
/**
 * POST /users
 * @param {Object} params
 * @param {Object} body
 */


function create(params, body) {
  // no multipath for /self or /organisations/{id}
  return Promise.resolve({
    method: "post",
    url: `/v2/users`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    // no query params
    body
  });
}

export default exports;
export const __esModule = exports.__esModule;
const _todo_changeUserPassword = exports.todo_changeUserPassword,
      _todo_getSelfCliTokens = exports.todo_getSelfCliTokens,
      _todo_getConfirmationEmail = exports.todo_getConfirmationEmail,
      _todo_getEmailAddresses = exports.todo_getEmailAddresses,
      _todo_removeEmailAddress = exports.todo_removeEmailAddress,
      _todo_addEmailAddress = exports.todo_addEmailAddress,
      _getId = exports.getId,
      _todo_getSshKeys = exports.todo_getSshKeys,
      _todo_removeSshKey = exports.todo_removeSshKey,
      _todo_addSshKey = exports.todo_addSshKey,
      _todo_deleteMFA = exports.todo_deleteMFA,
      _todo_createMFA = exports.todo_createMFA,
      _todo_favMFA = exports.todo_favMFA,
      _todo_getBackupCodes = exports.todo_getBackupCodes,
      _todo_validateMFA = exports.todo_validateMFA,
      _todo_revokeAllTokens = exports.todo_revokeAllTokens,
      _todo_getSelfTokens = exports.todo_getSelfTokens,
      _todo_revokeToken = exports.todo_revokeToken,
      _todo_validateEmail = exports.todo_validateEmail,
      _getSummary = exports.getSummary,
      _create = exports.create;
export { _todo_changeUserPassword as todo_changeUserPassword, _todo_getSelfCliTokens as todo_getSelfCliTokens, _todo_getConfirmationEmail as todo_getConfirmationEmail, _todo_getEmailAddresses as todo_getEmailAddresses, _todo_removeEmailAddress as todo_removeEmailAddress, _todo_addEmailAddress as todo_addEmailAddress, _getId as getId, _todo_getSshKeys as todo_getSshKeys, _todo_removeSshKey as todo_removeSshKey, _todo_addSshKey as todo_addSshKey, _todo_deleteMFA as todo_deleteMFA, _todo_createMFA as todo_createMFA, _todo_favMFA as todo_favMFA, _todo_getBackupCodes as todo_getBackupCodes, _todo_validateMFA as todo_validateMFA, _todo_revokeAllTokens as todo_revokeAllTokens, _todo_getSelfTokens as todo_getSelfTokens, _todo_revokeToken as todo_revokeToken, _todo_validateEmail as todo_validateEmail, _getSummary as getSummary, _create as create };